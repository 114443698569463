import { SLOTS_CONTAINER_WIDTH } from '../config';

export const FREESPINS_PANEL_CONTAINER_POS_Y = 690;

export const FREESPINS_PANEL_MARGIN_X = 9;
export const FREESPINS_PANEL_MARGIN_Y = 17;

export const FREESPINS_PANEL_POS_X_LANDSCAPE = SLOTS_CONTAINER_WIDTH / 2 - 175;
export const FREESPINS_PANEL_POS_X_PORTRAIT = SLOTS_CONTAINER_WIDTH / 2;
export const FREESPINS_PANEL_POS_Y = 105;

export const FREESPINS_PANEL_PORTRAIT_SCALE = 1.5;

export const FREE_SPIN_ANIMATION_SCALE = 1.3;
export const FREE_SPIN_ANIMATION_DELAY = 500;
export const FREE_SPIN_ANIMATION_LOOP = false;

export const COUNT_DOWN_ANIMATION_NAME = 'fsCountDown';
export const COUNT_DOWN_DEFAULT_ANIMATION_STEP = ['countdown_0', 'countdown_1', 'countdown_2', 'countdown_3'];
export const COUNT_DOWN_SP_ANIMATION_STEP = ['countdown_0_sp', 'countdown_1_sp', 'countdown_2_sp', 'countdown_3_sp'];

export const FS_TRANSITION_ANIMATION_NAME = 'fsTransition';
export const FS_TRANSITION_ANIMATION = 'fs_transition';
export const FS_TRANSITION_INDICATOR_ANIMATION = 'fs_transition_indicator';
