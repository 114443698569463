import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isBuyFeatureMode } from '../../utils';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import { BG_FREE_SPIN_LANDSCAPE_SPRITE_Y, BG_FREE_SPIN_PORTRAIT_SPRITE_Y } from './config';

class FreeSpinBackground extends ViewContainer {
  private freeSpinBg1 = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.slotFreespinBgState1));
  private freeSpinBg2 = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.slotFreespinBgState2));
  private freeSpinBg3 = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.slotFreespinBgState3));

  private bgSprite = new PIXI.Sprite();

  private localWidth = 0;

  private localHeight = 0;

  constructor() {
    super();
    this.bgSprite.visible = false;
    this.bgSprite.texture = this.freeSpinBg1.texture;
    this.bgSprite.anchor.set(0.5, 0.0);
    this.addChild(this.bgSprite);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeModeBackground.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeModeBackground.bind(this));
  }

  private changeModeBackground(settings: { mode: GameMode }) {
    const { mode } = settings;
    if (mode === GameMode.REGULAR || isBuyFeatureMode(mode)) {
      this.bgSprite.visible = false;
    } else {
      this.bgSprite.visible = true;
    }
    if (mode === GameMode.FREE_SPINS_LVL1 || mode === GameMode.FREE_SPINS_LVL2) {
      this.bgSprite.texture = this.freeSpinBg1.texture;
    }
    if (mode === GameMode.FREE_SPINS_LVL3 || mode === GameMode.FREE_SPINS_LVL4) {
      this.bgSprite.texture = this.freeSpinBg2.texture;
    }
    if (mode === GameMode.FREE_SPINS_LVL5) {
      this.bgSprite.texture = this.freeSpinBg3.texture;
    }
  }

  private resize(width: number, height: number): void {
    this.localWidth = width;
    this.localHeight = height;
    this.x = width / 2;

    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }

  private resizeGameContainer(
    _width: number,
    _height: number,
    _x: number,
    y: number,
    scale: number,
    _pivotX: number,
    _pivotY: number,
  ): void {
    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = this.localWidth / this.localHeight;

    if (bgAspectRatio > aspectRatio) {
      this.y = y + BG_FREE_SPIN_PORTRAIT_SPRITE_Y * scale;
    } else {
      this.y = y + BG_FREE_SPIN_LANDSCAPE_SPRITE_Y * scale;
    }
  }
}

export default FreeSpinBackground;
