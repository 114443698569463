// 予兆振り分け
export const baseGamePhoenix = [25, 100];

// Animation selection table
export enum phoenixAnimationType {
  NON = 'No anticipation',
  PHOENIX = 'phoenix',
}

export const countDownLotTbl = [10, 100];

export enum countDownAnimationType {
  DEFAULT = 'default',
  RAINBOW = 'rainbow',
}
