import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { destroySpine, isBuyFeatureMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import {
  AMBIENT_ANIMATION_NAME,
  AMBIENT_ANIMATION_STATE1,
  AMBIENT_ANIMATION_STATE2,
  AMBIENT_ANIMATION_STATE3,
  BG_LANDSCAPE_SPINE_Y,
  BG_PORTRAIT_SPINE_Y,
  STATE1_TIME_SCALE,
  STATE2_TIME_SCALE,
  STATE3_TIME_SCALE,
} from './config';

class Background extends ViewContainer {
  private baseBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.slotBg));

  private bgSprite = new PIXI.Sprite();

  private animations: SpineAnimation[] = [];

  private localWidth = 0;

  private localHeight = 0;

  private localMode = GameMode.REGULAR;

  constructor() {
    super();
    this.bgSprite.texture = this.baseBg.texture;
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeModeBackground.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeModeBackground.bind(this));
  }

  private initFreeSpinsLevel12Animations(): void {
    const ambientFSAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources[AMBIENT_ANIMATION_NAME]!.spineData);
    ambientFSAnimation.addOnStart(() => {
      this.addChild(ambientFSAnimation.getSpine());
      ambientFSAnimation.setAnimation(AMBIENT_ANIMATION_STATE1, true);
    });
    ambientFSAnimation.getSpine().state.timeScale = STATE1_TIME_SCALE;
    this.animations.push(ambientFSAnimation);

    ambientFSAnimation.start();
  }

  private initFreeSpinsLevel34Animations(): void {
    const ambientFSAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources[AMBIENT_ANIMATION_NAME]!.spineData);
    ambientFSAnimation.addOnStart(() => {
      this.addChild(ambientFSAnimation.getSpine());
      ambientFSAnimation.setAnimation(AMBIENT_ANIMATION_STATE2, true);
    });
    this.animations.push(ambientFSAnimation);
    ambientFSAnimation.getSpine().state.timeScale = STATE2_TIME_SCALE;
    ambientFSAnimation.start();
  }

  private initFreeSpinsLevel5Animations(): void {
    const ambientFSAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources[AMBIENT_ANIMATION_NAME]!.spineData);
    ambientFSAnimation.addOnStart(() => {
      this.addChild(ambientFSAnimation.getSpine());
      ambientFSAnimation.setAnimation(AMBIENT_ANIMATION_STATE3, true);
    });
    this.animations.push(ambientFSAnimation);
    ambientFSAnimation.getSpine().state.timeScale = STATE3_TIME_SCALE;
    ambientFSAnimation.start();
  }

  private changeModeBackground(settings: { mode: GameMode }) {
    const { mode } = settings;
    const delay = Tween.createDelayAnimation(1000);
    if (mode === this.localMode) {
      return;
    }

    if (mode === GameMode.REGULAR || isBuyFeatureMode(mode)) {
      this.bgSprite.visible = true;
      this.bgSprite.y = 0;
    }

    if (mode === GameMode.FREE_SPINS_LVL1) {
      this.sceneChangeLevel12();
    }

    if (mode === GameMode.FREE_SPINS_LVL2) {
      if (mode - this.localMode == 1) {
        delay.start();
        delay.addOnComplete(() => {
          this.sceneChangeLevel12();
        });
      } else {
        this.sceneChangeLevel12();
      }
    }

    if (mode === GameMode.FREE_SPINS_LVL3 || mode === GameMode.FREE_SPINS_LVL4) {
      if (mode - this.localMode == 1) {
        delay.start();
        delay.addOnComplete(() => {
          this.sceneChangeLevel34();
        });
      } else {
        this.sceneChangeLevel34();
      }
    }

    if (mode === GameMode.FREE_SPINS_LVL5) {
      if (mode - this.localMode == 1) {
        delay.start();
        delay.addOnComplete(() => {
          this.sceneChangeLevel5();
        });
      } else {
        this.sceneChangeLevel5();
      }
    }
    this.localMode = mode;
  }

  private removeAnimation() {
    this.animations.forEach((animation) => {
      this.removeChild(animation.getSpine());
      destroySpine(animation);
    });
    this.animations = [];
  }

  private sceneChangeLevel12() {
    this.removeAnimation();
    this.bgSprite.visible = false;
    this.initFreeSpinsLevel12Animations();
    this.addChild(this.bgSprite);
    this.bgChangeResize();
  }

  private sceneChangeLevel34() {
    this.removeAnimation();
    this.bgSprite.visible = false;
    this.initFreeSpinsLevel34Animations();
    this.addChild(this.bgSprite);
    this.bgChangeResize();
  }

  private sceneChangeLevel5() {
    this.removeAnimation();
    this.bgSprite.visible = false;
    this.initFreeSpinsLevel5Animations();
    this.addChild(this.bgSprite);
    this.bgChangeResize();
  }

  private bgChangeResize() {
    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = this.localWidth / this.localHeight;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(this.localHeight / this.bgSprite.height);
      this.animations.forEach((animation) => {
        animation.spine.y = BG_PORTRAIT_SPINE_Y;
      });
    } else {
      this.scale.set(this.localWidth / this.bgSprite.width);
      this.animations.forEach((animation) => {
        animation.spine.y = BG_LANDSCAPE_SPINE_Y;
      });
    }
  }

  private resize(width: number, height: number): void {
    this.localWidth = width;
    this.localHeight = height;
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }

    if (setGameMode() === GameMode.REGULAR || isBuyFeatureMode(setGameMode())) {
      this.bgSprite.y = 0;
    } else {
      if (bgAspectRatio > aspectRatio) {
        this.animations.forEach((animation) => {
          animation.spine.y = BG_PORTRAIT_SPINE_Y;
        });
      } else {
        this.animations.forEach((animation) => {
          animation.spine.y = BG_LANDSCAPE_SPINE_Y;
        });
      }
    }
  }
}

export default Background;
