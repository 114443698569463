import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpriteAnimation from '../animations/sprite';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  COINS_AMOUNT_PER_SEC,
  SINGLE_COIN_ANIMATION_DURATION,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';

class CoinsAnimationContainer extends ViewContainer {
  public coinSheet: PIXI.Spritesheet;

  public nWidth: number = this.width;

  public nHeight: number = this.height;

  public interval: ReturnType<typeof setTimeout> | null = null;

  constructor() {
    super();
    this.nHeight = SLOTS_CONTAINER_HEIGHT + 50;
    this.nWidth = SLOTS_CONTAINER_WIDTH;
    this.coinSheet = PIXI.Loader.shared.resources['coinsAnimation']!.spritesheet!;
    eventManager.addListener(EventTypes.SHOW_COINS, () => this.startCoinsAnimation(true));
    eventManager.addListener(EventTypes.HIDE_COINS, () => this.startCoinsAnimation(false));
  }

  public startCoinsAnimation(visibility: boolean): void {
    this.visible = visibility;
    if (visibility) {
      this.interval = setInterval(() => this.createRandomAnimation().start(), 1000 / COINS_AMOUNT_PER_SEC);
    } else if (this.interval != null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  public createRandomAnimation(): AnimationGroup {
    const animationGroup = new AnimationGroup();
    const coinAnimation = new SpriteAnimation({ isLoop: true }, Object.values(this.coinSheet?.textures));
    this.addChild(coinAnimation.spriteAnimation);
    coinAnimation.spriteAnimation.animationSpeed = 5 / 25;
    coinAnimation.spriteAnimation.loop = true;
    coinAnimation.spriteAnimation.anchor.set(0.5, 0.5);
    const landing = { x: Math.random() * this.nWidth, y: 100 };
    const moveXAnimation = new Tween({
      object: coinAnimation.spriteAnimation,
      property: TweenProperties.X,
      propertyBeginValue: this.nWidth / 2,
      target: landing.x,
      duration: SINGLE_COIN_ANIMATION_DURATION,
    });
    const rotateAnimation = new Tween({
      object: coinAnimation.spriteAnimation,
      property: TweenProperties.ROTATION,
      propertyBeginValue: 0,
      target: Math.PI * 4,
      duration: SINGLE_COIN_ANIMATION_DURATION,
    });
    const moveYAnimation = new AnimationChain();
    const pickValue = Math.random() * 150 + 50;
    const moveUpYAnimation = new Tween({
      object: coinAnimation.spriteAnimation,
      property: TweenProperties.Y,
      propertyBeginValue: this.nHeight,
      target: pickValue,
      duration: SINGLE_COIN_ANIMATION_DURATION / 2,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 2 / 5),
    });
    const moveDownYAnimation = new Tween({
      object: coinAnimation.spriteAnimation,
      property: TweenProperties.Y,
      propertyBeginValue: pickValue,
      target: this.nHeight,
      duration: SINGLE_COIN_ANIMATION_DURATION / 2,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 3),
    });
    moveYAnimation.appendAnimation(moveUpYAnimation);
    moveYAnimation.appendAnimation(moveDownYAnimation);
    animationGroup.addAnimation(coinAnimation);
    animationGroup.addAnimation(rotateAnimation);
    animationGroup.addAnimation(moveYAnimation);
    animationGroup.addAnimation(moveXAnimation);
    animationGroup.addOnComplete(() => {
      this.removeChild(coinAnimation.spriteAnimation);
      coinAnimation.skip();
    });
    return animationGroup;
  }
}

export default CoinsAnimationContainer;
