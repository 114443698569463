import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import {
  SetIsCountUp,
  setBetAmount,
  setCurrency,
  setCurrentFreeSpinsTotalWin,
  setGameMode,
  setIsDuringBigWinLoop,
} from '../../gql/cache';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { isBaseGameMode, isFreeSpinsMode, normalizeCoins, showCurrency } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { LayeredText } from '../components/layeredText';
import {
  APPLICATION_FPS,
  BASE_WIN_AMOUNT_LIMIT,
  BASE_WIN_COUNT_UP_MULTIPLIER,
  BASE_WIN_TITLE_SCALE,
  BIG_WIN_AMOUNT_LIMIT,
  BIG_WIN_COUNT_UP_MULTIPLIER,
  BIG_WIN_END_DURATION,
  BIG_WIN_TITLE_SCALE,
  DOUBLE_WIN_AMOUNT_LIMIT,
  EPIC_WIN_COUNT_UP_MULTIPLIER,
  EPIC_WIN_TITLE_SCALE,
  GREAT_WIN_AMOUNT_LIMIT,
  GREAT_WIN_COUNT_UP_MULTIPLIER,
  GREAT_WIN_TITLE_SCALE,
  MAXIMUM_FRACTION_DIGITS,
  MEGA_WIN_AMOUNT_LIMIT,
  MEGA_WIN_COUNT_UP_MULTIPLIER,
  MEGA_WIN_TITLE_SCALE,
  MINIMUM_FRACTION_DIGITS,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  WIN_CHECK_TIME_DURATION_ON_FREE_SPIN,
  WinStages,
  countUpTextStyle,
  eventManager,
} from '../config';

class WinCountUpMessage extends PIXI.Container {
  public winValue = 0.0;

  public winCountUpAnimation: Animation | null = null;

  private winTitle = new LayeredText(
    this.winValue.toLocaleString('en-EN', {
      minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
      maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
    }),
    countUpTextStyle,
  );

  constructor() {
    super();
    this.winTitle.y = SLOTS_CONTAINER_HEIGHT - 65;
    this.winTitle.x = SLOTS_CONTAINER_WIDTH / 2;
    this.winTitle.visible = false;
    this.addChild(this.winTitle);
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.startWinAnimation.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpinAnimation.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE, this.hideWinCountUpMessage.bind(this));
  }

  private hideWinCountUpMessage(): void {
    this.winTitle.visible = false;
  }

  private onStartSpinAnimation(): void {
    this.winCountUpAnimation?.skip();
  }

  private startWinAnimation(
    nextResult: ISettledBet,
    _isTurboSpin: boolean,
    isScatter: boolean,
    isExpand: boolean,
  ): void {
    SetIsCountUp(true);
    this.createWinAnimation(nextResult, isScatter, isExpand).start();
  }

  private skipWinCountUpAnimation() {
    this.winCountUpAnimation?.skip();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isNumber(arg: any): arg is number {
    return typeof arg === 'number';
  }

  public createWinAnimation(nextResult: ISettledBet, _isScatter: boolean, isExpand: boolean): Animation {
    const betAmount = normalizeCoins(setBetAmount());
    const winAmount = normalizeCoins(nextResult.bet.result.winCoinAmount);
    const stage = this.countStage(betAmount, winAmount);

    const animationChain = new AnimationChain({
      proceedNextAnimationOnSkip: true,
    });

    if (stage <= WinStages.BaseWin) {
      const baseWinAnimation = this.createBaseWinAnimation(winAmount, betAmount);
      baseWinAnimation.addOnStart(() => {
        AudioApi.play({ type: ISongs.Win_Loop, stopPrev: true });
      });
      baseWinAnimation.addOnComplete(() => {
        AudioApi.stop({ type: ISongs.Win_Loop });
        AudioApi.play({ type: ISongs.Win_End, stopPrev: true });
      });
      baseWinAnimation.addOnSkip(() => {
        this.setWinValue(winAmount);
        AudioApi.stop({ type: ISongs.Win_Loop });
        AudioApi.play({ type: ISongs.Win_End, stopPrev: true });
      });
      animationChain.appendAnimation(baseWinAnimation);
    } else {
      animationChain.addOnStart(() => {
        eventManager.emit(EventTypes.SHOW_COINS);
      });
      animationChain.addOnSkip(() => {
        eventManager.emit(EventTypes.HIDE_COINS);
      });
      animationChain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_COINS);
      });

      if (stage >= WinStages.BigWin) {
        const bigWinAnimationGroup = new AnimationGroup();
        const bigWinAnimation = this.createBigWinAnimation(winAmount, betAmount, stage);

        const bigWinBgmChain = new AnimationChain();
        const bigWinStartDelayAnimation = Tween.createDelayAnimation(mappedAudioSprites[ISongs.Win_Loop].duration);
        bigWinStartDelayAnimation.addOnComplete(() => {
          if (isBaseGameMode(setGameMode())) {
            // TODO
          }
        });

        bigWinBgmChain.addOnComplete(() => {
          setIsDuringBigWinLoop(true);
          AudioApi.play({ type: ISongs.Win_Loop, stopPrev: true });
        });

        bigWinBgmChain.addOnSkip(() => {
          if (isBaseGameMode(setGameMode())) {
            // TODO
          }
          setIsDuringBigWinLoop(true);
          AudioApi.play({ type: ISongs.Win_Loop, stopPrev: true });
        });

        bigWinBgmChain.appendAnimation(bigWinStartDelayAnimation);

        bigWinAnimationGroup.addAnimation(bigWinAnimation);
        bigWinAnimationGroup.addAnimation(bigWinBgmChain);
        animationChain.appendAnimation(bigWinAnimationGroup);
      }
      if (stage >= WinStages.MegaWin) {
        const megaWinAnimation = this.createMegaWinAnimation(winAmount, betAmount, stage);
        animationChain.appendAnimation(megaWinAnimation);
      }
      if (stage >= WinStages.GreatWin) {
        const greatWinAnimation = this.createGreatWinAnimation(winAmount, betAmount, stage);
        animationChain.appendAnimation(greatWinAnimation);
      }
      if (stage >= WinStages.EpicWin) {
        const epicWinAnimation = this.createEpicWinAnimation(winAmount, betAmount);
        animationChain.appendAnimation(epicWinAnimation);
      }
    }

    if (stage >= WinStages.BigWin) {
      const bigWinEndDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.BigWin_End].duration);
      bigWinEndDelay.addOnStart(() => {
        setIsDuringBigWinLoop(false);
        AudioApi.stop({ type: ISongs.Win_Loop });
      });
      bigWinEndDelay.addOnSkip(() => {
        AudioApi.stop({ type: ISongs.BigWin_End });
      });
      bigWinEndDelay.addOnComplete(() => {});
      animationChain.appendAnimation(bigWinEndDelay);
    } else {
      let delayTime = WIN_CHECK_TIME_DURATION_ON_FREE_SPIN;
      if (winAmount === 0) {
        delayTime = 0;
      }
      const winEndDelay = Tween.createDelayAnimation(delayTime);
      winEndDelay.addOnComplete(() => {});
      animationChain.appendAnimation(winEndDelay);
    }
    if (!isFreeSpinsMode(setGameMode())) {
      const fadeOutAnimation = new Tween({
        propertyBeginValue: 1,
        target: 0,
        object: this.winTitle,
        easing: (n) => Math.pow(n, 8),
        property: TweenProperties.ALPHA,
        duration: BIG_WIN_END_DURATION,
      });

      fadeOutAnimation.addOnStart(() => {
        eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, stage);
        SetIsCountUp(false);
        eventManager.emit(EventTypes.COUNT_UP_END, isExpand);
      });
      fadeOutAnimation.addOnSkip(() => {
        eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
      });
      animationChain.appendAnimation(fadeOutAnimation);
    } else {
      let duration = 0;
      if (stage < WinStages.BigWin) {
        duration = WIN_CHECK_TIME_DURATION_ON_FREE_SPIN;
      }
      if (winAmount === 0) {
        duration = 0;
      }
      const fadeOutAnimation = Tween.createDelayAnimation(duration);

      fadeOutAnimation.addOnStart(() => {
        eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, stage);
        SetIsCountUp(false);
        eventManager.emit(EventTypes.COUNT_UP_END, isExpand, setCurrentFreeSpinsTotalWin() + winAmount);
      });
      fadeOutAnimation.addOnSkip(() => {
        eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
      });
      animationChain.appendAnimation(fadeOutAnimation);
    }
    animationChain.addOnStart(() => {
      if (winAmount > 0) {
        this.winTitle.alpha = 1;
        this.winTitle.visible = true;
        eventManager.emit(EventTypes.START_GET_AMOUNT_WIN);
      }
    });
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
      this.clean(stage);
    });
    animationChain.addOnSkip(() => {
      this.clean(stage);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
    });
    this.winCountUpAnimation = animationChain;
    return animationChain;
  }

  private winTitleDisable(): void {
    this.winTitle.visible = false;
    this.winTitle.scale.set(1, 1);
    this.setWinValue(0);
  }

  private clean(stage: WinStages): void {
    setIsDuringBigWinLoop(false);
    AudioApi.stop({ type: ISongs.Win_Loop });
    AudioApi.stop({ type: ISongs.SFX_WIN_EPIC });
    AudioApi.stop({ type: ISongs.SFX_WIN_GREAT });
    AudioApi.stop({ type: ISongs.SFX_WIN_MEGA });
    AudioApi.stop({ type: ISongs.SFX_WIN_BIG });

    if (stage >= WinStages.BigWin) {
      BgmControl.fadeInBase(3000);
    }
    this.winTitle.visible = false;
    this.winTitle.scale.set(1, 1);
    this.setWinValue(0);
    this.winCountUpAnimation = null;
  }

  private createBaseWinAnimation(win: number, bet: number): Animation {
    const baseWinAnimation = new AnimationGroup({});
    let duration = 0;
    let countUpAnimation;
    if (isFreeSpinsMode(setGameMode())) {
      duration = (win / bet / (BASE_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
      countUpAnimation = new Tween({
        propertyBeginValue: 0,
        target: win,
        object: this,
        property: TweenProperties.WIN_VALUE,
        update: this.setWinValue.bind(this),
        duration,
      });
    } else {
      duration = (win / bet / (BASE_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
      countUpAnimation = new Tween({
        propertyBeginValue: 0,
        target: Math.min(win, bet * BASE_WIN_AMOUNT_LIMIT),
        object: this,
        property: TweenProperties.WIN_VALUE,
        update: this.setWinValue.bind(this),
        duration,
      });
    }
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    baseWinAnimation.addAnimation(scaleXAnimation);
    baseWinAnimation.addAnimation(scaleYAnimation);
    baseWinAnimation.addAnimation(countUpAnimation);
    return baseWinAnimation;
  }

  private createBigWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const bigWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    bigWinAnimation.addOnStart(() => {
      BgmControl.fadeOutAll(1000);
      AudioApi.play({ type: ISongs.SFX_WIN_BIG, stopPrev: true });
      AudioApi.play({ type: ISongs.BigWin_Loop, stopPrev: true });
      eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, true);
    });
    bigWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_BIG });
      if (stage === WinStages.BigWin) {
        AudioApi.stop({ type: ISongs.BigWin_Loop });
        AudioApi.play({ type: ISongs.BigWin_End });
        eventManager.emit(EventTypes.HIDE_COINS);
      }

      if (stage > WinStages.BigWin) {
        eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      }
    });
    bigWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_BIG });
      if (stage === WinStages.BigWin) {
        AudioApi.stop({ type: ISongs.BigWin_Loop });
        AudioApi.play({ type: ISongs.BigWin_End });
        eventManager.emit(EventTypes.HIDE_COINS);
      }

      if (stage > WinStages.BigWin) {
        eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
      }
    });
    const duration =
      (Math.min(win / bet, BIG_WIN_AMOUNT_LIMIT) / (BIG_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT)));
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BIG_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BIG_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    bigWinAnimation.appendAnimation(countUpAnimationGroup);
    return bigWinAnimation;
  }

  private createMegaWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const megaWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    megaWinAnimation.addOnStart(() => {
      AudioApi.play({ type: ISongs.SFX_WIN_MEGA, stopPrev: true });
      eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, true);
    });
    megaWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_MEGA });
      if (stage === WinStages.MegaWin) {
        AudioApi.stop({ type: ISongs.BigWin_Loop });
        AudioApi.play({ type: ISongs.BigWin_End });
        eventManager.emit(EventTypes.HIDE_COINS);
      }
      if (stage > WinStages.MegaWin) {
        eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      }
    });
    megaWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_MEGA });
      if (stage === WinStages.MegaWin) {
        AudioApi.stop({ type: ISongs.BigWin_Loop });
        AudioApi.play({ type: ISongs.BigWin_End });
        eventManager.emit(EventTypes.HIDE_COINS);
      }
      if (stage > WinStages.MegaWin) {
        eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
      }
    });
    const duration =
      (Math.min(win / bet, MEGA_WIN_AMOUNT_LIMIT) / (MEGA_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1100;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * BIG_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT)));
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: BIG_WIN_TITLE_SCALE,
      target: MEGA_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: BIG_WIN_TITLE_SCALE,
      target: MEGA_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    megaWinAnimation.appendAnimation(countUpAnimationGroup);
    return megaWinAnimation;
  }

  private createGreatWinAnimation(win: number, bet: number, stage: WinStages): Animation {
    const greatWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    greatWinAnimation.addOnStart(() => {
      AudioApi.play({ type: ISongs.SFX_WIN_GREAT, stopPrev: true });
      eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, true);
    });
    greatWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_GREAT });
      if (stage === WinStages.GreatWin) {
        AudioApi.stop({ type: ISongs.BigWin_Loop });
        AudioApi.play({ type: ISongs.BigWin_End });
        eventManager.emit(EventTypes.HIDE_COINS);
      }
      if (stage > WinStages.GreatWin) {
        eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      }
    });
    greatWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_GREAT });
      if (stage === WinStages.GreatWin) {
        AudioApi.stop({ type: ISongs.BigWin_Loop });
        AudioApi.play({ type: ISongs.BigWin_End });
        eventManager.emit(EventTypes.HIDE_COINS);
      }
      if (stage > WinStages.GreatWin) {
        eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
      }
    });
    const duration =
      (Math.min(win / bet, GREAT_WIN_AMOUNT_LIMIT) / (GREAT_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1100;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * MEGA_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT),
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnSkip(() => this.setWinValue(Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT)));
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: MEGA_WIN_TITLE_SCALE,
      target: GREAT_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: MEGA_WIN_TITLE_SCALE,
      target: GREAT_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    greatWinAnimation.appendAnimation(countUpAnimationGroup);
    return greatWinAnimation;
  }

  private createEpicWinAnimation(win: number, bet: number): Animation {
    const epicWinAnimation = new AnimationChain();
    const countUpAnimationGroup = new AnimationGroup({});
    epicWinAnimation.addOnStart(() => {
      AudioApi.play({ type: ISongs.SFX_WIN_EPIC });
      eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, true);
    });
    epicWinAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_EPIC });
      AudioApi.stop({ type: ISongs.BigWin_Loop });
      eventManager.emit(EventTypes.HIDE_COINS);
      AudioApi.play({ type: ISongs.BigWin_End });
    });
    epicWinAnimation.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SFX_WIN_EPIC });
      AudioApi.stop({ type: ISongs.BigWin_Loop });
      eventManager.emit(EventTypes.HIDE_COINS);
      AudioApi.play({ type: ISongs.BigWin_End });
      this.setWinValue(win);
    });

    let duration = (win / bet / (EPIC_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1100;
    if (win / bet === 100) {
      duration = 100;
    }
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * GREAT_WIN_AMOUNT_LIMIT,
      target: win,
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: GREAT_WIN_TITLE_SCALE,
      target: EPIC_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: GREAT_WIN_TITLE_SCALE,
      target: EPIC_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    countUpAnimationGroup.addAnimation(scaleXAnimation);
    countUpAnimationGroup.addAnimation(scaleYAnimation);
    countUpAnimationGroup.addAnimation(countUpAnimation);
    epicWinAnimation.appendAnimation(countUpAnimationGroup);

    return epicWinAnimation;
  }

  public setWinValue(winValue: number): void {
    this.winValue = winValue < 0 ? 0 : winValue;
    this.winTitle.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
  }

  private countStage(bet: number, win: number): WinStages {
    const multiplier = win / bet;

    if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
      return WinStages.None;
    }
    if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
      return WinStages.BaseWin;
    }
    if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
      return WinStages.BigWin;
    }
    if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
    if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
    return WinStages.EpicWin;
  }

  private getCoinAmount(nextResult: ISettledBet, isScatter: boolean, isExpand: boolean): number {
    const payLine = nextResult.paylines;
    let nullCount = 0;

    let rtn = 0;
    payLine.forEach((line) => {
      if (isExpand) {
        if (line.lineId == null) {
          // expand win
          if (line.winPositions.length > 0) {
            rtn = line.amount * 10;
          }
        }
      } else {
        if (line.lineId != null) {
          // normal win
          if (line.winPositions.length > 0) {
            rtn += line.amount;
          }
        } else {
          if (isScatter && nullCount === 0) {
            // scatter win
            if (line.winPositions.length > 0) {
              rtn += line.amount * 10;
              nullCount++;
            }
          }
        }
      }
    });
    return rtn;
  }
}

export default WinCountUpMessage;
