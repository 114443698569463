import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';

import { EventTypes, ISettledBet } from '../../global.d';
import {
  setIsSlotBusy,
  setIsSpinInProgress,
  setNextResult,
  setPrevReelsPosition,
  setProgress,
  setSlotConfig,
  setUserBalance,
} from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import Animator from '../../slotMachine/animations/animator';
import { REELS_AMOUNT, eventManager } from '../../slotMachine/config';
import { getSpinResult4X5, wrap } from '../../utils/helper';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsPopup';
import BetSettingsPopup from '../BetSettings/BetSettingsPopup';
import BuyFeature from '../BuyFeature';
import EventListener from '../EventListener';
import HistoryPopup from '../History/HistoryPopup';
import InfoPopup from '../Info/InfoPopup';
import IntroScreen from '../IntroScreen/introScreen';
import Spin from '../SpinButton';

import styles from './gameScreen.module.scss';
import { preTextureTransfer } from './preTextureTransfer';

window.PIXI = PIXI;

const GameScreen: React.FC = () => {
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isSoundOn } = clientData!;
  const [, setShowContent] = useState(false);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const progress = useReactiveVar(setProgress);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    const application = new PIXI.Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
    });

    preTextureTransfer(application);
    application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    new Animator(application);
    pixiContainerRef.current?.appendChild(application.view);

    const resize = (): void => {
      const parent = pixiContainerRef.current!;
      const width = parent.clientWidth;
      const height = parent.clientHeight;
      application.renderer.resize(width, height);
      eventManager.emit(EventTypes.RESIZE, width, height);
    };

    IntroScreen.initIntroScreen(application);
    eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      SlotMachine.initSlotMachine(
        setSlotConfig(),
        application,
        wrap(setIsSpinInProgress, false),
        wrap(setIsSlotBusy, false),
      );
      SlotMachine.getInstance().setResult = (result: ISettledBet) => {
        const spinResult = getSpinResult4X5({
          reelPositions: result.bet.result.reelPositions.slice(0, REELS_AMOUNT),
          reelSet: setSlotConfig().reels.find((reelSet) => reelSet.id === result.bet.reelSet.id)!,
          icons: setSlotConfig().icons,
        });
        const newResult = {
          ...result,
          bet: {
            ...result.bet,
            result: {
              ...result.bet.result,
              spinResult,
            },
          },
        };
        SlotMachine.getInstance().nextResult = newResult;

        setPrevReelsPosition(newResult.bet.result.reelPositions.slice(0, REELS_AMOUNT));
        setNextResult(newResult);

        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, SlotMachine.getInstance().nextResult!.balance.placed);
        setUserBalance({ ...setUserBalance(), balance: newResult.balance.placed });
      };
      resize();
    });

    resize();
    window.addEventListener(EventTypes.RESIZE, resize);

    document.addEventListener('visibilitychange', function () {
      eventManager.emit(EventTypes.WINDOW_NOT_ACTIVE);
    });

    return () => window.removeEventListener(EventTypes.RESIZE, resize);
  }, []);

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <>
      <div ref={pixiContainerRef} className={`${styles['canvas']} ${progress.wasLoaded ? '' : styles['intro']}`} />
      <EventListener />
      <BuyFeature />
      <AutoPlaySettingsMenu />
      <InfoPopup />
      <HistoryPopup />
      <BetSettingsPopup />
      <Spin />
    </>
  );
};

export default GameScreen;
