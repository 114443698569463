import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config/audio';
import { EventTypes, GameMode } from '../../global.d';
import { setIsFadeOut } from '../../gql/cache';
import { destroySpine, updateCoinValueAfterBonuses } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import BgmControl from '../bgmControl/bgmControl';
import ViewContainer from '../components/container';
import { FREE_SPINS_FADE_DURATION, eventManager } from '../config';
import { FS_TRANSITION_ANIMATION, FS_TRANSITION_ANIMATION_NAME } from '../freeSpinCounter/config';

class FadeArea extends ViewContainer {
  private sprite: PIXI.Sprite;

  constructor() {
    super();
    this.sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.sprite.width = 100;
    this.sprite.height = 100;
    this.sprite.tint = 0x000000;
    this.sprite.alpha = 0;
    this.addChild(this.sprite);
    eventManager.addListener(EventTypes.START_RETRIGGER_ANIMATION, this.startRetriggerAnimation.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.START_MODE_CHANGE_FADE, this.startModeChangeFade.bind(this));
  }

  private startRetriggerAnimation(): void {
    const spine = new SpineAnimation({}, PIXI.Loader.shared.resources[FS_TRANSITION_ANIMATION_NAME]!.spineData);

    spine.addOnStart(() => {
      const spineData = spine.getSpine();
      spineData.x = this.width / 2;
      spineData.y = this.height / 2;

      this.addChild(spineData);
      spine.setAnimation(FS_TRANSITION_ANIMATION, false);
      BgmControl.stopBgm();
    });

    spine.addOnStart(() =>
      AudioApi.play({
        type: ISongs.LvUpBanner,
      }),
    );

    spine.addOnComplete(() => {
      eventManager.emit(EventTypes.END_RETRIGGER_ANIMATION);
      destroySpine(spine);
    });
    spine.start();
  }

  private resize(width: number, height: number): void {
    this.sprite.width = width;
    this.sprite.height = height;
  }

  private startModeChangeFade(settings: { mode: GameMode; reelPositions: number[]; reelSetId: string }): void {
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, FREE_SPINS_FADE_DURATION / 2);
    animationChain.addOnStart(() => {
      setIsFadeOut(true);
      eventManager.emit(EventTypes.SET_IS_FADEOUT, true);
    });
    animationChain.addOnComplete(() => {
      setIsFadeOut(false);
      eventManager.emit(EventTypes.SET_IS_FADEOUT, false);
    });
    fadeOut.addOnComplete(() => {
      if (settings.mode === GameMode.REGULAR) {
        updateCoinValueAfterBonuses();
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      }
      eventManager.emit(EventTypes.CHANGE_MODE, settings);
    });
    const fadeIn = this.getFadeAnimation(0, FREE_SPINS_FADE_DURATION / 2);
    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.start();
  }

  private getFadeAnimation(alpha: number, duration: number): Animation {
    const animation = new Tween({
      object: this.sprite,
      duration,
      propertyBeginValue: alpha === 1 ? 0 : 1,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }
}

export default FadeArea;
