import { isMobile } from 'mobile-device-detect';

import { FreeSpinsTitleProps } from '../../global.d';
import ViewContainer from '../components/container';

import {
  FREESPINS_PANEL_CONTAINER_POS_Y,
  FREESPINS_PANEL_MARGIN_X,
  FREESPINS_PANEL_MARGIN_Y,
  FREESPINS_PANEL_POS_X_LANDSCAPE,
  FREESPINS_PANEL_POS_X_PORTRAIT,
} from './config';
import CountDownText from './countDownText';
import { FreeSpinsCounter } from './freeSpinCounter';
import { LevelIndicater } from './indicater/levelIndicater';

export class FreeSpinsPanel extends ViewContainer {
  private indicater: LevelIndicater;

  private spinCounter: FreeSpinsCounter;

  private countDownText: CountDownText;

  constructor(props: FreeSpinsTitleProps) {
    super();

    this.spinCounter = new FreeSpinsCounter(props);
    this.addChild(this.spinCounter);

    this.indicater = new LevelIndicater();
    this.addChild(this.indicater);

    this.countDownText = new CountDownText(props);
    this.addChild(this.countDownText);

    this.position.set(67, -120);

    let posX = 0;
    const posY = this.height / 2;
    [this.indicater, this.spinCounter].forEach((item) => {
      item.pivot.set(0, item.height / 2);
      item.position.set(posX, posY);
      posX += item.width + FREESPINS_PANEL_MARGIN_X;
    });

    this.y = FREESPINS_PANEL_CONTAINER_POS_Y;
  }

  private onResize(width: number, height: number): void {
    if (isMobile && width < height) {
      this.setPortraitLayout();
    } else {
      this.setLandscapeLayout();
    }
  }

  private setLandscapeLayout(): void {
    this.pivot.set(this.width / 2, this.height / 2);
    this.position.x = FREESPINS_PANEL_POS_X_LANDSCAPE;
  }

  private setPortraitLayout(): void {
    this.reset();

    const posX = this.width / 2;
    let posY = 0;

    [this.indicater, this.spinCounter].forEach((item) => {
      item.pivot.set(item.width / 2, 0);
      item.position.set(posX, posY);
      posY += item.height + FREESPINS_PANEL_MARGIN_Y;
    });

    this.pivot.set(this.width / 2, this.height);
    this.position.x = FREESPINS_PANEL_POS_X_PORTRAIT;
  }

  private reset(): void {
    this.children.forEach((i) => i.position.set(0, 0));
  }
}
export default FreeSpinsPanel;
