// eslint-disable-next-line
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, FreeSpinsTitleProps, MessageBannerProps } from '../../global.d';
import Tween from '../animations/tween';
import BgmControl from '../bgmControl/bgmControl';
import ViewContainer from '../components/container';
import {
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  LINES_NUMBER_CONTAINER_WIDTH,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import FreeSpinsPanel from '../freeSpinCounter/freeSpinsPanel';
import MessageBanner from '../messageBanner/messageBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import { RetriggerMessageBanner } from '../messageBanner/retriggerMessageBanner';
import ReelsFrame from '../reels/frame/reelsFrame';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public gameReplay: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public frame: ReelsFrame;

  private messageWinBanner: MessageWinBanner | undefined;

  public freeSpinsPanel: FreeSpinsPanel | undefined;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = SHOW_LINES_NUMBER_CONTAINER ? LINES_NUMBER_CONTAINER_WIDTH : 0;
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.frame = new ReelsFrame();
    this.slotsContainer.addChild(this.tintContainer);
    this.addChild(this.reelsBackgroundContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.slotsContainer.mask);
    this.addChild(this.slotsContainer);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.frame);
    this.addChild(this.gameTitle);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.gameReplay);

    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CREATE_FREE_SPINS_TITLE, this.createFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.REMOVE_FREE_SPINS_TITLE, this.removeFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_RETRIGGER_MESSAGE_BANNER, this.createRetriggerMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessage.bind(this));
  }

  private createFreeSpinsMessage(props: MessageBannerProps): void {
    this.addChildAt(new MessageBanner(props), this.getPositionOfFreeSpinPanelInScene());
  }

  private getPositionOfFreeSpinPanelInScene(): number {
    const position = this.children.findIndex((child) => child instanceof FreeSpinsPanel);

    return position !== -1 ? position : 6;
  }

  private createWinMessage(
    props: MessageBannerProps & {
      totalWin: string;
      totalSpins: number;
      level: number;
    },
  ): void {
    const totalWinDelay = Tween.createDelayAnimation(1000);
    totalWinDelay.addOnStart(() => {
      BgmControl.fadeOutAll(250);
    });
    totalWinDelay.addOnComplete(() => {
      BgmControl.stopBgm();
    });
    totalWinDelay.addOnSkip(() => {
      BgmControl.stopBgm();
      AudioApi.fadeOut(1000, ISongs.TotalWinBanner);
    });
    AudioApi.play({ type: ISongs.TotalWinBanner, stopPrev: true });
    totalWinDelay.start();
    this.addChildAt(
      new MessageWinBanner({
        ...props,
        callback: () => {
          totalWinDelay.skip();
          if (props.callback) props.callback();
        },
      }).init(),
      this.getPositionOfFreeSpinPanelInScene(),
    );
  }

  private createRetriggerMessage(props: MessageBannerProps & { level: number; lastLevelRetriger: boolean }): void {
    this.addChildAt(new RetriggerMessageBanner(props).init(), this.getPositionOfFreeSpinPanelInScene());
  }

  private removeFreeSpinsTitle(): void {
    if (this.freeSpinsPanel) {
      this.removeChild(this.freeSpinsPanel);
      this.freeSpinsPanel?.destroy({
        children: true,
      });
      this.freeSpinsPanel = undefined;
    }
  }

  private createFreeSpinsTitle(props: FreeSpinsTitleProps): void {
    this.freeSpinsPanel = new FreeSpinsPanel(props);
    this.addChildAt(this.freeSpinsPanel, this.getPositionOfFreeSpinPanelInScene());
  }

  private resize(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }
}

export default GameView;
