import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EventTypes, type IAuthInput } from '../../global.d';
import {
  setCurrentBonus,
  setIsSoundOn,
  setIsTimeoutErrorMessage,
  setReplayBet,
  setReplayFreeSpinBets,
  setStressful,
} from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { configGql, getProgressGql } from '../../gql/query';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { parseQuery, queryParams } from '../../utils';
import GameScreen from '../GameScreen';
import LoadScreen from '../LoadScreen';

const App: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  // TODO: need to understand why slot is crash without this shit
  useReactiveVar(setIsSoundOn);
  const { data: dataProgress } = useQuery<{
    progress: {
      status: number;
      wasLoaded?: boolean;
    };
  }>(getProgressGql);
  const { i18n } = useTranslation();
  const { progress } = dataProgress!;

  const handleIdle = useCallback(
    (state: SlotMachineState) => {
      if (state !== SlotMachineState.IDLE) return;
      const replayFreeSpins = setReplayFreeSpinBets();
      if (!replayFreeSpins.length || setCurrentBonus().totalRounds === replayFreeSpins.length) {
        setIsTimeoutErrorMessage(true);
        setStressful({
          show: true,
          type: 'none',
          message: i18n.t('replayBetMessage'),
        });
      }
    },
    [i18n],
  );

  useEffect(() => {
    if (queryParams.has('replayBetId')) {
      setReplayBet(queryParams.get('replayBetId') || '');
    }
    eventManager.on(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      setTimeout(() => {
        if (setReplayBet()) {
          eventManager.emit(EventTypes.SET_REPLAY_BET_ID);
          const replayFreeSpins = setReplayFreeSpinBets();
          if (replayFreeSpins.length) {
            const triggerSpin = replayFreeSpins.shift();
            setReplayBet(triggerSpin);
          }
          eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
          eventManager.emit(EventTypes.TOGGLE_SPIN);
          if (setCurrentBonus().isActive && setCurrentBonus().betId) {
            eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, () => {
              eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
                handleIdle(state);
              });
            });
          } else {
            eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
              handleIdle(state);
            });
          }
        }
      });
    });
  }, [i18n, handleIdle]);

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);

  return (
    <>
      <LoadScreen />
      {data?.isAuthorized && progress?.status >= 100 && <GameScreen />}
    </>
  );
};

export default App;
